import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { useLocation } from "react-router";
import Logger from "../../modules/Logger";

const FacebookPixel = ({ isTrackViewContent }) => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === "production" && navigator.userAgent !== "ReactSnap") {
      ReactPixel.pageView();

      if (isTrackViewContent) {
        const timer = setTimeout(() => {
          Logger.info("ViewContent");
          ReactPixel.track("ViewContent");
        }, 10000);
        return () => clearTimeout(timer);
      }
    }
  }, [pathname, hash, isTrackViewContent]);
  return null;
};

export default FacebookPixel;
