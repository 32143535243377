import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ru from "./locales/ru.json";
import uk from "./locales/uk.json";
import en from "./locales/en.json";

export const availableLanguages = [
  { key: "ru", title: "Русский (РУС)", titleShort: "RU" },
  { key: "uk", title: "Українська (УКР)", titleShort: "UK" },
  { key: "en", title: "English (ENG)", titleShort: "EN" },
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    lng: "uk",
    resources: {
      ru: { translation: ru },
      uk: { translation: uk },
      en: { translation: en },
    },
    supportedLngs: ["en", "ru", "uk"],
    fallbackLng: "dev",
    load: "unspecific",
    nsSeparator: "::",
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: false,
      useSuspense: false,
    },
  });

export default i18n;
