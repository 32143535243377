import { ADD_ARTICLE_TO_BLOG, BLOG_LIST } from "../actions";

const initialState = {
  articles: [
    {
      id: 1,
      createdAt: "12.07.2019 12:00",
      title: "Title",
      text: "Text",
      pic: require("../../assets/img_easy/blog.jpg").default,
    },
    {
      id: 2,
      createdAt: "12.07.2019 12:00",
      title: "Title222",
      text: "Text222",
      pic: require("../../assets/img_easy/blog.jpg").default,
    },
  ],
  blogList: [],
  blogListNextCursor: null,
};

export default function addArticleToBlogReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ARTICLE_TO_BLOG:
      return {
        ...state,
        articles: [...state.articles, ...[action.payload]],
      };
    case BLOG_LIST:
      return {
        ...state,
        blogList: action.payload.data,
        blogListNextCursor: action.payload.nextCursor,
      };
    default:
      return state;
  }
}
