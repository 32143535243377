import { API } from "../../modules/Api.js";
import { toggleAddArticle, toggleDeleteVisualization } from "./index";
import Logger from "../../modules/Logger";
export const ADD_ARTICLE_TO_BLOG = "ADD_ARTICLE_TO_BLOG";
export const BLOG_LIST = "BLOG_LIST";

export const finalAdding = (article) => (dispatch) => {
  dispatch(addArticleToBlog(article));
};

function addArticleToBlog(article) {
  return {
    type: ADD_ARTICLE_TO_BLOG,
    payload: article,
  };
}

function blogList(data, nextCursor) {
  return {
    type: BLOG_LIST,
    payload: { data, nextCursor },
  };
}

export const createBlog = (data) => (dispatch) => {
  API.post("/blog", data).then(
    (response) => {
      console.log(response);
      dispatch(getBlogList(1));
      dispatch(toggleAddArticle({ isAddArticle: false, isArticleId: null }));
    },
    (error) => {
      console.log(error);
    },
  );
};

export const updateBlog = (id, data) => (dispatch) => {
  API.patch(`/blog/${id}`, data).then(
    (response) => {
      console.log(response);
      dispatch(getBlogList(1));
      dispatch(addArticleToBlog(false));
      dispatch(toggleAddArticle({ isAddArticle: false, isArticleId: null }));
    },
    (error) => {
      console.log(error);
    },
  );
};

export const clearBlogList = () => (dispatch) => {
  dispatch(blogList([], null));
};

export const getBlogList = (page) => (dispatch, getState) => {
  if (page === 1) {
    dispatch(clearBlogList());
  }
  const nextCursor = getState().addArticleToBlog.blogListNextCursor;
  return API.get("/blog", {
    params: {
      cursor: nextCursor,
    },
  }).then((response) => {
    const currentblogList = page !== 1 ? [...getState().addArticleToBlog.blogList] : [];
    const blogListResults = [...currentblogList, ...response.data.results];

    dispatch(blogList(blogListResults, response.data.next));
  });
};

export const deleteBlog = (id) => (dispatch) => {
  return API.delete(`/blog/${id}`).then(
    (response) => {
      console.log(response.data);
      dispatch(getBlogList(1));
      dispatch(toggleDeleteVisualization({ isShow: false, isUserId: null }));
    },
    (error) => {
      Logger.info(error);
    },
  );
};
