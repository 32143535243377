import MomentUtils from "@date-io/moment";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uk";
import React, { lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router";
import "./App.scss";
import { reconnectSocket } from "./modules/Socket";
import { OpenRoute, PrivateRoute } from "./Routes";
import FacebookPixel from "./components/FacebookPixel/FacebookPixel";
import ReactPixel from "react-facebook-pixel";
import { FB_PIXEL_ID } from "./constants";

const LoginLayout = lazy(() => import(/* webpackChunkName: "LoginLayout" */ "./layouts/LoginLayout"));
const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage" */ "./layouts/LoginPage"));
const RegistrationPage = lazy(() => import(/* webpackChunkName: "RegistrationPage" */ "./layouts/RegistrationPage"));
const ConfirmPage = lazy(() => import(/* webpackChunkName: "ConfirmPage" */ "./layouts/ConfirmPage"));
const ProjectPage = lazy(() => import(/* webpackChunkName: "ProjectPage" */ "./layouts/ProjectPage"));
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */ "./layouts/MainLayout"));
const CreditsLayout = lazy(() => import(/* webpackChunkName: "CreditsLayout" */ "./layouts/CreditsLayout"));

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#00bcd4",
    },
  },
});

const App = () => {
  const { i18n } = useTranslation();

  const { userId, isAuth } = useSelector(({ login, profile }) => ({ isAuth: login.isAuth, userId: profile.id }));

  const [momentLocale, setMomentLocale] = useState("uk");
  useEffect(() => {
    const momentLocales = {
      ru: "ru",
      en: "en-gb",
      uk: "uk",
    };
    const currentLocale = momentLocales[i18n.language ? i18n.language : "uk"];
    setMomentLocale(currentLocale);
    moment.locale(currentLocale);
  }, [i18n.language, momentLocale]);

  useEffect(() => {
    if (userId) {
      reconnectSocket(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (process.env.NODE_ENV === "production" && navigator.userAgent !== "ReactSnap") {
      ReactPixel.init(FB_PIXEL_ID);
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={momentLocale}>
        <FacebookPixel isTrackViewContent />
        {/*<AcceptCookies />*/}
        <Switch>
          <PrivateRoute path="/profile" component={MainLayout} auth={isAuth} />
          <PrivateRoute path="/patients" component={MainLayout} auth={isAuth} />
          <PrivateRoute path="/companies-list" component={MainLayout} auth={isAuth} />
          <PrivateRoute path="/tutorial" component={MainLayout} auth={isAuth} />
          <PrivateRoute path="/support" component={MainLayout} auth={isAuth} />
          <PrivateRoute path="/finance" component={MainLayout} auth={isAuth} />
          <PrivateRoute path="/appointments" component={MainLayout} auth={isAuth} />
          <PrivateRoute path="/contacts" component={MainLayout} auth={isAuth} />
          <PrivateRoute path="/smile-assessment" component={MainLayout} auth={isAuth} />
          <PrivateRoute path="/admin-blog" component={MainLayout} auth={isAuth} />
          <OpenRoute exact path="/share_project/:token" component={ProjectPage} />
          <OpenRoute exact path="/login" component={LoginPage} />
          <OpenRoute exact path="/change_email/:token" component={ConfirmPage} />
          <OpenRoute exact path="/sign_up/:token" component={RegistrationPage} />
          <OpenRoute exact path={["/privacy-policy", "/terms-of-use", "/e-contract"]} component={CreditsLayout} />
          <OpenRoute path="/" component={LoginLayout} />
          <Redirect from="*" to="/" />
        </Switch>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
