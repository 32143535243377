import {
  SET_CARDS_USER,
  SET_CARDS_LIST_CANCEL_TOKEN,
  SET_3D_STL,
  START_OBTAIN_CARDS_LIST,
  OBTAIN_CARDS_LIST_DATA,
  OBTAIN_CARDS_LIST_ERROR,
  TOGGLE_SHARE_PROJECT,

  TOGGLE_WINDOW_THUMBNAIL,
  RESET_WINDOW_THUMBNAIL_DATA,
} from "../actions";

const initialState = {
  cardsUser: null,

  cardsList: [],
  cardsListError: [],
  cardsListNextCursor: null,
  cardsListCancelToken: null,
  cardsListIsLoading: true,

  isShareProject: false,
  isShareId: null,





  currentView: "",
  stlData: [],
  isOpen: false,
  isMini: false,
};

export default function cardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CARDS_USER:
      return { ...state, cardsUser: action.payload };
    case SET_CARDS_LIST_CANCEL_TOKEN:
      return { ...state, cardsListCancelToken: action.payload };
    case START_OBTAIN_CARDS_LIST:
      return { ...state, cardsListIsLoading: true };
    case OBTAIN_CARDS_LIST_DATA:
      return {
        ...state,
        cardsListIsLoading: false,
        cardsList: action.payload.data,
        cardsListNextCursor: action.payload.nextCursor,
        cardsListError: [],
      };
    case OBTAIN_CARDS_LIST_ERROR:
      return {
        ...state,
        cardsListIsLoading: false,
        cardsListError: action.payload,
      };
    case TOGGLE_SHARE_PROJECT:
      return {
        ...state,
        isShareProject: action.payload.isShow,
        isShareId: action.payload.isShareId,
      };

    case TOGGLE_WINDOW_THUMBNAIL:
      return { ...state, isOpen: action.payload, isMini: false };
    case RESET_WINDOW_THUMBNAIL_DATA:
      return { ...state, currentView: "", stlData: [] };
    case SET_3D_STL:
      return { ...state, currentView: "stlPreview", stlData: action.payload };
    default:
      return state;
  }
}
