import {
  OBTAIN_SIDEBAR_CONTACTS_DATA,
  OBTAIN_SIDEBAR_CONTACTS_ERROR,
  OBTAIN_CONTACTS_DATA,
  OBTAIN_CONTACTS_ERROR,
} from "../actions";

const initialState = {
  // Contacts for sidebar
  contactsSidebarList: [],
  contactsSidebarListNextCursor: null,
  contactsSidebarListErrors: [],

  // Contacts for all contacts list
  contactsList: [],
  contactsListTotal: 0,
  contactsListNextCursor: null,
  contactsListErrors: [],
};

export default function contactsReducer(state = initialState, action) {
  switch (action.type) {
    case OBTAIN_SIDEBAR_CONTACTS_DATA:
      return {
        ...state,
        contactsSidebarList: action.payload.contactsList,
        contactsSidebarListNextCursor: action.payload.nextCursor,
        contactsSidebarListErrors: [],
      };
    case OBTAIN_SIDEBAR_CONTACTS_ERROR:
      return {
        ...state,
        contactsSidebarListErrors: action.payload.errors,
      };
    case OBTAIN_CONTACTS_DATA:
      return {
        ...state,
        contactsList: action.payload.contactsList,
        contactsListTotal: action.payload.contactsTotal,
        contactsListNextCursor: action.payload.nextCursor,
        contactsListErrors: [],
      };
    case OBTAIN_CONTACTS_ERROR:
      return {
        ...state,
        contactsListErrors: action.payload.errors,
      };
    default:
      return state;
  }
}
