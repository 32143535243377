import { API } from "../../modules/Api";
import { CancelToken } from "axios";
import Logger from "../../modules/Logger";
import moment from "moment";

export const OBTAINT_UPCOMING_APPOINTMENTS_DATA = "OBTAINT_UPCOMING_APPOINTMENTS_DATA";
export const OBTAINT_UPCOMING_APPOINTMENTS_ERROR = "OBTAINT_UPCOMING_APPOINTMENTS_ERROR";
export const OBTAINT_UPCOMING_APPOINTMENTS_DATA_NOW = "OBTAINT_UPCOMING_APPOINTMENTS_DATA_NOW";
export const OBTAINT_UPCOMING_APPOINTMENTS_ERROR_NOW = "OBTAINT_UPCOMING_APPOINTMENTS_ERROR_NOW";
export const OBTAINT_UPCOMING_APPOINTMENTS_DATA_LOADING = "OBTAINT_UPCOMING_APPOINTMENTS_DATA_LOADING";
export const OBTAINT_UPCOMING_APPOINTMENTS_CANCEL_TOKEN = "OBTAINT_UPCOMING_APPOINTMENTS_CANCEL_TOKEN";
export const OBTAINT_UPCOMING_APPOINTMENTS_CANCEL_TOKEN_NOW = "OBTAINT_UPCOMING_APPOINTMENTS_CANCEL_TOKEN_NOW";

function obtainUpcomingAppointmentsData(data, nextCursor, count) {
  return {
    type: OBTAINT_UPCOMING_APPOINTMENTS_DATA,
    payload: { data, nextCursor, count },
  };
}

function obtainUpcomingAppointmentsError(error) {
  return {
    type: OBTAINT_UPCOMING_APPOINTMENTS_ERROR,
    payload: error,
  };
}
function obtainUpcomingAppointmentsDataNow(dataNow, nextCursorNow, countNow) {
  return {
    type: OBTAINT_UPCOMING_APPOINTMENTS_DATA_NOW,
    payload: { dataNow, nextCursorNow, countNow },
  };
}

function obtainUpcomingAppointmentsErrorNow(errorNow) {
  return {
    type: OBTAINT_UPCOMING_APPOINTMENTS_ERROR_NOW,
    payload: errorNow,
  };
}
function obtainUpcomingAppointmentsDataLoading(isLoading) {
  return {
    type: OBTAINT_UPCOMING_APPOINTMENTS_DATA_LOADING,
    payload: isLoading,
  };
}

export const clearUpcomingAppointmentsList = () => (dispatch) => {
  dispatch(obtainUpcomingAppointmentsData([], null, 0));
};
export const clearUpcomingAppointmentsListNow = () => (dispatch) => {
  dispatch(obtainUpcomingAppointmentsDataNow([], null, 0));
};

function obtainAppointmentsCancelToken(token) {
  return {
    type: OBTAINT_UPCOMING_APPOINTMENTS_CANCEL_TOKEN,
    payload: token,
  };
}
function obtainAppointmentsCancelTokenNow(tokenNow) {
  return {
    type: OBTAINT_UPCOMING_APPOINTMENTS_CANCEL_TOKEN_NOW,
    payload: tokenNow,
  };
}

export const getUpcomingAppointments = (page) => (dispatch, getState) => {
  const currentCancelToken = getState().upcomingAppointments.upcomingAppointmentsListCancelToken;
  if (currentCancelToken) {
    currentCancelToken();
  }
  dispatch(obtainUpcomingAppointmentsDataLoading(true));
  if (page === 1) {
    dispatch(clearUpcomingAppointmentsList());
  }
  const nextCursor = getState().upcomingAppointments.upcomingAppointmentsListCursor;
  API.get("appointment", {
    cancelToken: new CancelToken((c) => {
      dispatch(obtainAppointmentsCancelToken(c));
    }),
    params: {
      datetime__gte: moment().format("DD.MM.YYYY"),
      datetime__lte: moment().add(2, "days").format("DD.MM.YYYY"),
      cursor: nextCursor,
    },
  }).then(
    (response) => {
      Logger.info(response);
      const upcomingAppointmentsList = [
        ...getState().upcomingAppointments.upcomingAppointmentsList,
        ...response.data.results,
      ];
      const newNextCursor = response.data.next;

      dispatch(obtainUpcomingAppointmentsData(upcomingAppointmentsList, newNextCursor, response.data.count));
      dispatch(obtainUpcomingAppointmentsDataLoading(false));
    },
    (error) => {
      Logger.info(error);
      obtainUpcomingAppointmentsError("upcoming uppointmets error");
    },
  );
};

export const getUpcomingAppointmentsNow = (page) => (dispatch, getState) => {
  const currentCancelTokenNow = getState().upcomingAppointments.upcomingAppointmentsListCancelTokenNow;
  if (currentCancelTokenNow) {
    currentCancelTokenNow();
  }
  dispatch(obtainUpcomingAppointmentsDataLoading(true));
  if (page === 1) {
    dispatch(clearUpcomingAppointmentsListNow());
  }
  const nextCursorNow = getState().upcomingAppointments.upcomingAppointmentsListCursorNow;
  API.get("appointment", {
    cancelToken: new CancelToken((c) => {
      dispatch(obtainAppointmentsCancelTokenNow(c));
    }),
    params: {
      exact_creation_date: moment().format("DD.MM.YYYY"),
      cursor: nextCursorNow,
    },
  }).then(
    (response) => {
      Logger.info(response);
      const upcomingAppointmentsListNow = [
        ...getState().upcomingAppointments.upcomingAppointmentsListNow,
        ...response.data.results,
      ];
      const newNextCursorNow = response.data.next;

      dispatch(obtainUpcomingAppointmentsDataNow(upcomingAppointmentsListNow, newNextCursorNow, response.data.count));
      // dispatch(obtainUpcomingAppointmentsDataLoading(false));
    },
    (error) => {
      Logger.info(error);
      obtainUpcomingAppointmentsErrorNow("upcoming uppointmets error");
    },
  );
};
