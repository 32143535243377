import io from "socket.io-client";
import {
  addSingleCard,
  getMessages,
  getSidebarContacts,
  getSingleChatBoxMessage,
  getSmileList,
  getSupportList,
  getUpcomingAppointments,
  getUpcomingAppointmentsNow,
  getUserUnreadNotifications,
  updateSingleCard,
} from "../redux/actions";
import Logger from "./Logger";

let socket, socketStore;

export const getSocket = () => socket;
export const closeSocket = () => (socket ? socket.close() : null);
export const createSocketConnection = (store) => {
  socketStore = store;
  if (socket) {
    socket.close();
  }
  socket = io(`${process.env.REACT_APP_WSS}/notifications`);

  return socket;
};

export const reconnectSocket = (userId) => {
  if (socket) {
    socket.close();
  }
  socket = io(`${process.env.REACT_APP_WSS}/notifications?id=${userId}`);

  attachAppCommands();

  return socket;
};

const attachAppCommands = () => {
  socket.on("message", (data) => {
    Logger.info("new socket message", data);
    // TODO here we need some optimization to set only one contact if exists
    const accountType = socketStore.getState().profile.account_type;
    const currentUser = socketStore.getState().chat.currentUser;
    if (accountType === "owner") {
      socketStore.dispatch(getSidebarContacts(1));
      socketStore.dispatch(getSingleChatBoxMessage(data.message_id));
    }
    if (currentUser === data.sender_id) {
      socketStore.dispatch(getSingleChatBoxMessage(data.message_id));
      console.log("get message ", data.message_id);
    }
    Logger.info(socketStore);
  });

  socket.on("notifications", (data) => {
    Logger.info("Socket on support", data);
    switch (data.type) {
      case "SupportRequestCreate":
        console.log("support notifications");
        socketStore.dispatch(getSupportList(1));
        break;
      case "SmileAssessmentRequestCreate":
        console.log("smile notifications");
        socketStore.dispatch(getSmileList(1));
        break;
      default:
        console.log("none notifications", data);
    }
  });

  socket.on("cards", (data) => {
    console.log('"cards", (data)', data);
    switch (data.type) {
      case "AlignersOrderCreate":
      case "AlignersOrderUpdate":
      case "AlignersOrderPay":
      case "TreatmentPlanCreate":
      case "TreatmentPlanUpdate":
      case "TreatmentPlanPay":
      case "ShipmentDataCreate":
      case "ShipmentDataUpdate":
      case "SetUpDataCreate":
      case "SetUpDataUpdate":
        socketStore.dispatch(getUserUnreadNotifications(1));
        socketStore.dispatch(updateSingleCard(data.card_id));
        break;
      case "TreatmentPlanConfirm":
        socketStore.dispatch(getUserUnreadNotifications(1));
        break;
      case "CardUpdate":
        socketStore.dispatch(getUserUnreadNotifications(1));
        socketStore.dispatch(updateSingleCard(data.id));
        break;
      case "AppointmentCreate":
        socketStore.dispatch(getUpcomingAppointments(1));
        socketStore.dispatch(updateSingleCard(data.card_id));
        socketStore.dispatch(getUpcomingAppointmentsNow(1));
        break;
      case "CardCreate":
        socketStore.dispatch(addSingleCard(data.id));
        socketStore.dispatch(getUserUnreadNotifications(1));
        break;
      case "TreatmentPlanMessageCreate":
        socketStore.dispatch(getUserUnreadNotifications(1));
        socketStore.dispatch(getMessages(data.treatment_plan_id, data.sender_id));
        break;
      default:
        console.log("Uncatched socket appointments event", data);
    }
  });
};

export const socketEmmit = (type, payload) => {
  if (socket) {
    socket.emit(type, payload);
  }
};
