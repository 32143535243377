import { OBTAIN_PROFILE_DATA, START_OBTAIN_PROFILE_DATA, OBTAIN_PROFILE_ERROR } from "../actions";

const initialState = {
  isLoading: true,
  id: null,
  account_type: "",
  avatar: "",
  name: "",
  clinic: "",
  phone_number: "",
  country: "",
  address: "",
  additional_phone_number: "",
  // theme: "",
  errors: [],
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case START_OBTAIN_PROFILE_DATA:
      return {
        ...state,
        isLoading: true,
        errors: [],
      };
    case OBTAIN_PROFILE_DATA:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
        errors: [],
      };
    case OBTAIN_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
