import {
  SET_INVOICES_DATA_LOADING,
  OBTAIN_INVOICES_DATA,
  OBTAIN_INVOICES_ERROR,
  SET_INVOICES_CANCEL_TOKEN,
} from "../actions";

const initialState = {
  invoicesListLoading: false,
  invoicesList: [],
  invoicesListNextCursor: "",
  invoicesListErrors: [],
  invoicesListCancelToken: null,
};

export default function invoicesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INVOICES_DATA_LOADING:
      return {
        ...state,
        invoicesListLoading: action.payload,
      };
    case OBTAIN_INVOICES_DATA:
      return {
        ...state,
        invoicesList: action.payload.invoicesList,
        invoicesListNextCursor: action.payload.nextCursor,
      };
    case OBTAIN_INVOICES_ERROR:
      return {
        ...state,
        invoicesListErrors: action.payload,
      };
    case SET_INVOICES_CANCEL_TOKEN:
      return {
        ...state,
        invoicesListCancelToken: action.payload,
      };
    default:
      return state;
  }
}
