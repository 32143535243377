import moment from "moment";
import { TPC_GET_MESSAGES, TPC_SEND_MESSAGE } from "../actions";

const initialState = {
  treatmentPlans: {},
};

/**
 * treatmentPlans: {
 *  [treatmentPlanID]: {
 *    [Date]: {
 *      [
 *        {message_1_data},
 *        {message_2_data},
 *        {message_3_data}
 *      ]
 *    }
 *  }
 * }
 */

export default function treatmentPlanChatReducer(state = initialState, { type, payload }) {
  switch (type) {
    case TPC_GET_MESSAGES:
      return {
        ...state,
        treatmentPlans: { ...state.treatmentPlans, [payload.treatmentPlanID]: payload.groupedMessages },
      };
    case TPC_SEND_MESSAGE:
      const currentDate = moment(Date.now()).format("DD.MM.YY");
      const prevTodayMessages = state.treatmentPlans[payload.treatmentPlanID][currentDate];

      if (!prevTodayMessages) {
        return {
          ...state,
          treatmentPlans: {
            ...state.treatmentPlans,
            [payload.treatmentPlanID]: {
              [currentDate]: [payload.messageData],
            },
          },
        };
      }
      return {
        ...state,
        treatmentPlans: {
          ...state.treatmentPlans,
          [payload.treatmentPlanID]: {
            ...state.treatmentPlans[payload.treatmentPlanID],
            [currentDate]: [...prevTodayMessages, payload.messageData],
          },
        },
      };

    default:
      return state;
  }
}
