import {
  OBTAINT_UPCOMING_APPOINTMENTS_DATA,
  OBTAINT_UPCOMING_APPOINTMENTS_ERROR,
  OBTAINT_UPCOMING_APPOINTMENTS_DATA_NOW,
  OBTAINT_UPCOMING_APPOINTMENTS_ERROR_NOW,
  OBTAINT_UPCOMING_APPOINTMENTS_DATA_LOADING,
  OBTAINT_UPCOMING_APPOINTMENTS_CANCEL_TOKEN,
  OBTAINT_UPCOMING_APPOINTMENTS_CANCEL_TOKEN_NOW,
} from "../actions";

const initialState = {
  upcomingAppointmentsList: [],
  upcomingAppointmentsListError: [],
  upcomingAppointmentsListCursor: null,
  upcomingAppointmentsListCount: 0,
  upcomingAppointmentsListNow: [],
  upcomingAppointmentsListErrorNow: [],
  upcomingAppointmentsListCursorNow: null,
  upcomingAppointmentsListCountNow: 0,
  upcomingAppointmentsListLoading: false,
  upcomingAppointmentsListCancelToken: null,
  upcomingAppointmentsListCancelTokenNow: null,
};

export default function upcomingAppointmentsReducer(state = initialState, action) {
  switch (action.type) {
    case OBTAINT_UPCOMING_APPOINTMENTS_DATA:
      return {
        ...state,
        upcomingAppointmentsList: action.payload.data,
        upcomingAppointmentsListCursor: action.payload.nextCursor,
        upcomingAppointmentsListCount: action.payload.count,
        upcomingAppointmentsListError: [],
      };
    case OBTAINT_UPCOMING_APPOINTMENTS_ERROR:
      return { ...state, upcomingAppointmentsListError: action.payload };
    case OBTAINT_UPCOMING_APPOINTMENTS_DATA_NOW:
      return {
        ...state,
        upcomingAppointmentsListNow: action.payload.dataNow,
        upcomingAppointmentsListCursorNow: action.payload.nextCursorNow,
        upcomingAppointmentsListCountNow: action.payload.countNow,
        upcomingAppointmentsListErrorNow: [],
      };
    case OBTAINT_UPCOMING_APPOINTMENTS_ERROR_NOW:
      return { ...state, upcomingAppointmentsListErrorNow: action.payload };
    case OBTAINT_UPCOMING_APPOINTMENTS_DATA_LOADING:
      return { ...state, upcomingAppointmentsListLoading: action.payload };
    case OBTAINT_UPCOMING_APPOINTMENTS_CANCEL_TOKEN:
      return { ...state, upcomingAppointmentsListCancelToken: action.payload };
    case OBTAINT_UPCOMING_APPOINTMENTS_CANCEL_TOKEN_NOW:
      return { ...state, upcomingAppointmentsListCancelTokenNow: action.payload };
    default:
      return state;
  }
}
