import { groupBy } from "lodash";
import moment from "moment";
import Logger from "../../modules/Logger";
import { API } from "../../modules/Api";

export const TPC_GET_MESSAGES = "TPC_GET_MESSAGES";
export const TPC_SEND_MESSAGE = "TPC_SEND_MESSAGE";

const getMessagesAction = ({ treatmentPlanID, groupedMessages }) => {
  return {
    type: TPC_GET_MESSAGES,
    payload: { treatmentPlanID, groupedMessages },
  };
};

const sendMessageSuccessAction = (treatmentPlanID, messageData) => {
  return {
    type: TPC_SEND_MESSAGE,
    payload: { treatmentPlanID, messageData },
  };
};

export const getMessages = (treatmentPlanID, senderID) => async (dispatch, getState) => {
  if (senderID === getState().profile.id) {
    return;
  }

  try {
    const { data } = await API.get(`treatment_plan/${treatmentPlanID}/message`);
    const groupedMessages = groupBy(data, (el) => moment(el.created_at).format("DD.MM.YY"));

    dispatch(getMessagesAction({ treatmentPlanID, groupedMessages }));
  } catch (error) {
    Logger.error(error);
  }
};

export const sendMessage = (treatmentPlanID, text, setMessageSending, setMessage, executeScroll) => async (
  dispatch,
) => {
  setMessageSending(true);
  try {
    const { data } = await API.post(`treatment_plan/${treatmentPlanID}/message`, { text });
    dispatch(sendMessageSuccessAction(treatmentPlanID, data));

    setMessage("");
    executeScroll();
  } catch (error) {
    Logger.error(error);
  } finally {
    setMessageSending(false);
  }
};
