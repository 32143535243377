import { API } from "../../modules/Api.js";
import { CancelToken } from "axios";
import Logger from "../../modules/Logger";
import { push } from "connected-react-router";
import { toggleDeleteVisualization, toggleDeleteCardObject } from "./index";
export const SET_CARDS_USER = "SET_CARDS_USER";
export const SET_CARDS_LIST_CANCEL_TOKEN = "SET_CARDS_LIST_CANCEL_TOKEN";
export const START_OBTAIN_CARDS_LIST = "START_OBTAIN_CARDS_LIST";
export const OBTAIN_CARDS_LIST_DATA = "OBTAIN_CARD_LIST_DATA";
export const OBTAIN_CARDS_LIST_ERROR = "OBTAIN_CARD_LIST_ERROR";

export const TOGGLE_SHARE_PROJECT = "TOGGLE_SHARE_PROJECT";

export const SET_3D_STL = "SET_3D_STL";
export const TOGGLE_WINDOW_THUMBNAIL = "TOGGLE_WINDOW_THUMBNAIL";
export const RESET_WINDOW_THUMBNAIL_DATA = "RESET_WINDOW_THUMBNAIL_DATA";

export function setCardsUser(userId) {
  return {
    type: SET_CARDS_USER,
    payload: userId,
  };
}

function cardsList(data, nextCursor) {
  return {
    type: OBTAIN_CARDS_LIST_DATA,
    payload: { data, nextCursor },
  };
}

function cardsListError(error) {
  return {
    type: OBTAIN_CARDS_LIST_ERROR,
    payload: error,
  };
}

function setCardsListCancelToken(token) {
  return {
    type: SET_CARDS_LIST_CANCEL_TOKEN,
    payload: token,
  };
}

function startObtainCardsList() {
  return {
    type: START_OBTAIN_CARDS_LIST,
  };
}

export function toggleShareProject({ isShow, isShareId }) {
  return {
    type: TOGGLE_SHARE_PROJECT,
    payload: { isShow, isShareId },
  };
}

function resetWindowThumbnail() {
  return {
    type: RESET_WINDOW_THUMBNAIL_DATA,
  };
}

function set3dStlModels(models) {
  return {
    type: SET_3D_STL,
    payload: models,
  };
}

function setWindowThumbnail(isShow) {
  return {
    type: TOGGLE_WINDOW_THUMBNAIL,
    payload: isShow,
  };
}

export const toggleWindowThumbnail = (isShow) => (dispatch) => {
  if (!isShow) {
    dispatch(resetWindowThumbnail());
  }
  dispatch(setWindowThumbnail(isShow));
};

export const stl3dPreviewAddSet = (stlFiles) => (dispatch) => {
  dispatch(set3dStlModels([...stlFiles]));
};

export const stl3dPreviewRemove = (name) => (dispatch, getState) => {
  const currentModels = getState().card.stlData;
  dispatch(set3dStlModels([...currentModels.filter((el) => el.displayName !== name)]));
};

export const getCardsList = (page, search) => (dispatch, getState) => {
  dispatch(startObtainCardsList());

  const currentCancelToken = getState().card.cardsListCancelToken;
  if (currentCancelToken) {
    currentCancelToken();
  }
  const currentUserId = getState().card.cardsUser;

  const nextCursor = page !== 1 ? getState().card.cardsListNextCursor : null;

  return API.get("/card", {
    cancelToken: new CancelToken((token) => {
      dispatch(setCardsListCancelToken(token));
    }),
    params: { cursor: nextCursor, id: currentUserId, search: search },
  }).then(
    (response) => {
      const currentCardsList = page !== 1 ? [...getState().card.cardsList] : [];
      const cardsListResults = [...currentCardsList, ...response.data.results];
      dispatch(cardsList(cardsListResults, response.data.next));
    },
    (error) => {
      Logger.info(error);
      dispatch(cardsListError(error));
    },
  );
};

export const addSingleCard = (cardId) => (dispatch, getState) => {
  API.get(`/card/${cardId}`).then((response) => {
    const updatedCards = [{ ...response.data }, ...getState().card.cardsList];
    const currentCursor = getState().card.cardsListNextCursor;
    dispatch(cardsList(updatedCards, currentCursor));
  });
};

export const updateSingleCard = (cardId) => (dispatch, getState) => {
  API.get(`/card/${cardId}`).then((response) => {
    const updatedCards = [
      ...getState().card.cardsList.map((el) => {
        if (el.id === response.data.id) {
          return { ...el, ...response.data };
        }
        return el;
      }),
    ];
    const currentCursor = getState().card.cardsListNextCursor;
    dispatch(cardsList(updatedCards, currentCursor));
  });
};

export const deleteCard = (id) => (dispatch, getState) => {
  return API.delete(`/card/${id}`).then(
    (response) => {
      Logger.info(response.data);
      dispatch(getCardsList(1));
      const currentUser = getState().card.cardsUser;
      dispatch(push(`/patients/${currentUser}/projects`));
      dispatch(toggleDeleteVisualization({ isShow: false, isUserId: null }));
    },
    (error) => {
      Logger.info(error);
    },
  );
};

export const deleteCardObject = (id, cardId) => (dispatch) => {
  return API.delete(`/card/${cardId}/object/${id}`).then(
    (response) => {
      Logger.info(response.data);
      dispatch(toggleDeleteCardObject({ isShow: false, cardId: null, isObjectId: null }));
    },
    (error) => {
      Logger.info(error);
    },
  );
};
