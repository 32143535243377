export const TOGGLE_CONTACT_US = "TOGGLE_CONTACT_US";
export const TOGGLE_COOKIE_POLICY = "TOGGLE_COOKIE_POLICY";
export const TOGGLE_TERMS_OF_USE = "TOGGLE_TERMS_OF_USE";
export const TOGGLE_TERMS_CONDITIONS = "TOGGLE_TERMS_CONDITIONS";
export const TOGGLE_CHANGE_EMAIL = "TOGGLE_CHANGE_EMAIL";
export const TOGGLE_CHANGE_PASSWORD = "TOGGLE_CHANGE_PASSWORD";
export const TOGGLE_DELETE_DIALOG = "TOGGLE_DELETE_DIALOG";
export const TOGGLE_DELETE_VISUALIZATION = "TOGGLE_DELETE_VISUALIZATION";
export const TOGGLE_DELETE_APPOINTMENT = "TOGGLE_DELETE_APPOINTMENT";
export const TOGGLE_ADD_VIDEO = "TOGGLE_ADD_VIDEO";
export const TOGGLE_ADD_IMAGE = "TOGGLE_ADD_IMAGE";
export const TOGGLE_EDIT_IMAGE = "TOGGLE_EDIT_IMAGE";
export const TOGGLE_EDIT_VIDEO = "TOGGLE_EDIT_VIDEO";
export const TOGGLE_SHARE_VISUALIZATION = "TOGGLE_SHARE_VISUALIZATION";
export const TOGGLE_IMAGE_PREVIEW_DIALOG = "TOGGLE_IMAGE_PREVIEW_DIALOG";
export const TOGGLE_DELETE_CARD_OBJECT = "TOGGLE_DELETE_CARD_OBJECT";
export const TOGGLE_CHANGE_STATUS = "TOGGLE_CHANGE_STATUS";
export const TOGGLE_TUTORIAL_PATIENTS = "TOGGLE_TUTORIAL_PATIENTS";
export const TOGGLE_TUTORIAL_COMPANY_LIST = "TOGGLE_TUTORIAL_COMPANY_LIST";
export const TOGGLE_TUTORIAL_VISUALISATION = "TOGGLE_TUTORIAL_VISUALISATION";
export const TOGGLE_TUTORIAL_PROFILE = "TOGGLE_TUTORIAL_PROFILE";
export const TOGGLE_TUTORIAL_PROJECT_CREATION = "TOGGLE_TUTORIAL_PROJECT_CREATION";
export const TOGGLE_TUTORIAL_ORDER_CREATION = "TOGGLE_TUTORIAL_ORDER_CREATION";
export const TOGGLE_TUTORIAL_SMILE_DESIGN = "TOGGLE_TUTORIAL_SMILE_DESIGN";
export const TOGGLE_TUTORIAL_AESTHETIC_MOCUP = "TOGGLE_TUTORIAL_AESTHETIC_MOCUP";
export const TOGGLE_SOURCE_REQUIREMENTS = "TOGGLE_SOURCE_REQUIREMENTS";

export const TOGGLE_ADD_ARTICLE = "TOGGLE_ADD_ARTICLE";

export function toggleShareVisualization({ isShow }) {
  return {
    type: TOGGLE_SHARE_VISUALIZATION,
    payload: { isShow },
  };
}

export function toggleEditVideo({ isShow }) {
  return {
    type: TOGGLE_EDIT_VIDEO,
    payload: { isShow },
  };
}
export function toggleEditImage({ isShow }) {
  return {
    type: TOGGLE_EDIT_IMAGE,
    payload: { isShow },
  };
}

export function toggleAddImage({ isShow }) {
  return {
    type: TOGGLE_ADD_IMAGE,
    payload: { isShow },
  };
}
export function toggleAddVideo({ isShow }) {
  return {
    type: TOGGLE_ADD_VIDEO,
    payload: { isShow },
  };
}

export function toggleTutorialPatients({ isShow }) {
  return {
    type: TOGGLE_TUTORIAL_PATIENTS,
    payload: { isShow },
  };
}
export function toggleTutorialCompanyList({ isShow }) {
  return {
    type: TOGGLE_TUTORIAL_COMPANY_LIST,
    payload: { isShow },
  };
}
export function toggleTutorialVisualisation({ isShow }) {
  return {
    type: TOGGLE_TUTORIAL_VISUALISATION,
    payload: { isShow },
  };
}
export function toggleTutorialProfile({ isShow }) {
  return {
    type: TOGGLE_TUTORIAL_PROFILE,
    payload: { isShow },
  };
}
export function toggleTutorialProjectCreation({ isShow }) {
  return {
    type: TOGGLE_TUTORIAL_PROJECT_CREATION,
    payload: { isShow },
  };
}
export function toggleTutorialOrderSmileDesign({ isShow }) {
  return {
    type: TOGGLE_TUTORIAL_ORDER_CREATION,
    payload: { isShow },
  };
}
export function toggleTutorialOrderCreation({ isShow }) {
  return {
    type: TOGGLE_TUTORIAL_SMILE_DESIGN,
    payload: { isShow },
  };
}
export function toggleTutorialOrderAestheticMocup({ isShow }) {
  return {
    type: TOGGLE_TUTORIAL_AESTHETIC_MOCUP,
    payload: { isShow },
  };
}
export function toggleSourceRequirements({ isShow }) {
  return {
    type: TOGGLE_SOURCE_REQUIREMENTS,
    payload: { isShow },
  };
}

export function toggleDeleteVisualization({ isShow, isUserId }) {
  return {
    type: TOGGLE_DELETE_VISUALIZATION,
    payload: { isShow, isUserId },
  };
}

export function toggleDeleteAppointment({ isShow, isAppointmentId, cardId }) {
  return {
    type: TOGGLE_DELETE_APPOINTMENT,
    payload: { isShow, isAppointmentId, cardId },
  };
}

export function toggleDeleteCardObject({ isShow, cardId, isObjectId }) {
  return {
    type: TOGGLE_DELETE_CARD_OBJECT,
    payload: { isShow, cardId, isObjectId },
  };
}

export function toggleAddArticle({ isShow, itemId }) {
  return {
    type: TOGGLE_ADD_ARTICLE,
    payload: { isShow, itemId },
  };
}

export function toggleChangeStatus({ isShow, cardId, isObjectStatusId }) {
  return {
    type: TOGGLE_CHANGE_STATUS,
    payload: { isShow, cardId, isObjectStatusId },
  };
}

export function toggleContactUs({ isShow }) {
  return {
    type: TOGGLE_CONTACT_US,
    payload: { isShow },
  };
}
export function toggleCookiePolicy({ isShow }) {
  return {
    type: TOGGLE_COOKIE_POLICY,
    payload: { isShow },
  };
}
export function toggleTermsOfUse({ isShow }) {
  return {
    type: TOGGLE_TERMS_OF_USE,
    payload: { isShow },
  };
}
export function toggleTermsConditions({ isShow }) {
  return {
    type: TOGGLE_TERMS_CONDITIONS,
    payload: { isShow },
  };
}
export function toggleChangeEmail({ isShow }) {
  return {
    type: TOGGLE_CHANGE_EMAIL,
    payload: { isShow },
  };
}
export function toggleChangePassword({ isShow }) {
  return {
    type: TOGGLE_CHANGE_PASSWORD,
    payload: { isShow },
  };
}

// Image Preview
function setImagePreview({ isShow, imagePreviewArray, imagePreviewIndex }) {
  return {
    type: TOGGLE_IMAGE_PREVIEW_DIALOG,
    payload: { isShow, imagePreviewArray, imagePreviewIndex },
  };
}

export const toggleImagePreview = ({ isShow, imagePreviewArray, imagePreviewIndex }) => (dispatch) => {
  if (isShow) {
    dispatch(setImagePreview({ isShow, imagePreviewArray, imagePreviewIndex }));
  } else {
    dispatch(setImagePreview({ isShow, imagePreviewArray, imagePreviewIndex }));
  }
};
