import { SMILE_LIST, SMILE_LIST_ERROR, SMILE_NOTIFIC } from "../actions";

const initialState = {
  smileList: [],
  smileListNextCursor: null,
  smileListErrors: [],
  smileNotificCount: null,
};

export default function smileReducer(state = initialState, action) {
  switch (action.type) {
    case SMILE_LIST:
      return {
        ...state,
        smileList: action.payload.data,
        smileListNextCursor: action.payload.nextCursor,
      };
    case SMILE_NOTIFIC:
      return { ...state, smileNotificCount: action.payload };
    case SMILE_LIST_ERROR:
      return { ...state, smileListErrors: action.payload };
    default:
      return state;
  }
}
