import { API } from "../../modules/Api";
import Logger from "../../modules/Logger";
import { toggleDeleteVisualization } from "./index";

export const OBTAIN_SIDEBAR_CONTACTS_DATA = "OBTAIN_SIDEBAR_CONTACTS_DATA";
export const OBTAIN_SIDEBAR_CONTACTS_ERROR = "OBTAIN_SIDEBAR_CONTACTS_ERROR";
export const OBTAIN_CONTACTS_DATA = "OBTAIN_CONTACTS_DATA";
export const OBTAIN_CONTACTS_ERROR = "OBTAIN_CONTACTS_ERROR";
export const USER_NAME = "USER_NAME";

function obtainSidebarContactsData(contactsList, nextCursor) {
  return {
    type: "OBTAIN_SIDEBAR_CONTACTS_DATA",
    payload: {
      contactsList: contactsList,
      nextCursor: nextCursor,
    },
  };
}

function obtainSidebarContactsError(errors) {
  return {
    type: "OBTAIN_SIDEBAR_CONTACTS_ERROR",
    payload: {
      errors: errors,
    },
  };
}

export const getSidebarContacts = (page) => (dispatch, getState) => {
  if (page === 1) {
    dispatch(obtainSidebarContactsData([], null));
  }
  const nextCursor = getState().contact.contactsSidebarListNextCursor;
  return API.get("/user/contacts", { params: { page: nextCursor } }).then(
    (response) => {
      Logger.info(response.data);
      const currentCardsList = page !== 1 ? [...getState().contact.contactsSidebarList] : [];
      let contactsList = [...currentCardsList, ...response.data.results];
      dispatch(obtainSidebarContactsData(contactsList, response.data.next));
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainSidebarContactsError(error));
    },
  );
};

export const markasreadSidebarContacts = (contactId) => (dispatch, getState) => {
  let contactsList = [
    ...getState().contact.contactsSidebarList.map((el) => (el.id === contactId ? { ...el, unread_pm: 0 } : el)),
  ];
  const nextCursor = getState().contact.contactsSidebarListNextCursor;
  dispatch(obtainSidebarContactsData(contactsList, nextCursor));
};

function obtainContactsData(contactsList, contactsTotal, nextCursor) {
  return {
    type: "OBTAIN_CONTACTS_DATA",
    payload: {
      contactsList: contactsList,
      contactsTotal: contactsTotal,
      nextCursor: nextCursor,
    },
  };
}

function obtainContactsError(errors) {
  return {
    type: "OBTAIN_CONTACTS_ERROR",
    payload: {
      errors: errors,
    },
  };
}

export const getContacts = (page, search = null) => (dispatch, getState) => {
  if (page === 1) {
    dispatch(obtainContactsData([], 0, null));
  }
  const nextCursor = getState().contact.contactsListNextCursor;
  return API.get("/user/contacts", { params: { page: nextCursor, search: search } }).then(
    (response) => {
      Logger.info(response.data);
      let contactsList = [...getState().contact.contactsList].concat(response.data.results);
      dispatch(obtainContactsData(contactsList, response.data.count, response.data.next));
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainContactsError(error));
    },
  );
};

export const deleteContact = (userId) => (dispatch) => {
  return API.delete(`/user/${userId}/delete`).then(
    (response) => {
      Logger.info(response.data);
      dispatch(getSidebarContacts(1));
      dispatch(getContacts(1));
      dispatch(toggleDeleteVisualization({ isShow: false, isUserId: null }));
    },
    (error) => {
      Logger.info(error);
      // dispatch(obtainContactsError(error));
    },
  );
};

export const addDoctors = (id) => (dispatch) => {
  return API.post(`/user/contacts/${id}/toggle`).then(() => {
    dispatch(getContacts(1));
  });
};
