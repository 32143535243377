import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import loginReducer from "./login";
import profileReducer from "./profile";
import cardReducer from "./card";
import dialogReducer from "./dialog";
import { createDialogsReducer } from "./dialogs";
import contactReducer from "./contact";
import chatReducer from "./chat";
import supportReducer from "./support";
import invoicesReducer from "./invoices";
import notificationsReducer from "./notifications";
import fileUploadReducer from "./fileUpload";
import appointmentsReducer from "./appointments";
import smileReducer from "./smile";
import snackbarNotificationReducer from "./snackbarNotification";
import upcomingAppointmentsReducer from "./upcomingAppointments";
import addArticleToBlogReducer from "./addArticleToBlog";
import treatmentPlanChatReducer from "./treatmentPlanChatReducer";

const mainReducer = (history) =>
  combineReducers({
    addArticleToBlog: addArticleToBlogReducer,
    appointments: appointmentsReducer,
    card: cardReducer,
    chat: chatReducer,
    contact: contactReducer,
    dialog: dialogReducer,
    fileUpload: fileUploadReducer,
    invoices: invoicesReducer,
    login: loginReducer,
    notifications: notificationsReducer,
    profile: profileReducer,
    support: supportReducer,
    smile: smileReducer,
    snackbarNotification: snackbarNotificationReducer,
    treatmentPlanChat: treatmentPlanChatReducer,
    upcomingAppointments: upcomingAppointmentsReducer,
    router: connectRouter(history),
    ...createDialogsReducer(),
  });

export default mainReducer;
