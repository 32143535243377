import React from "react";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./redux/store";

import App from "./App";
import "./i18n";
import "./index.css";

import * as serviceWorker from "./registerServiceWorker"; // dont use service worker for production

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    rootElement,
  );
} else {
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    rootElement,
  );
}

serviceWorker.unregister();
