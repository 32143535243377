import { API } from "../../modules/Api.js";
import Logger from "../../modules/Logger";
import { setAuth } from "./loginAction.js";
import { setCardAdd } from "./snackbarNotificationAction";

export const START_OBTAIN_PROFILE_DATA = "START_OBTAIN_PROFILE_DATA";
export const OBTAIN_PROFILE_DATA = "OBTAIN_PROFILE_DATA";
export const OBTAIN_PROFILE_ERROR = "OBTAIN_PROFILE_ERROR";

function startObtainProfileData() {
  return {
    type: START_OBTAIN_PROFILE_DATA,
  };
}

function obtainProfileData(data) {
  return {
    type: OBTAIN_PROFILE_DATA,
    payload: data,
  };
}

function obtainProfileError(error) {
  return {
    type: OBTAIN_PROFILE_ERROR,
    payload: error,
  };
}

// Get profile
export const getProfile = () => (dispatch) => {
  dispatch(startObtainProfileData());

  return API.get("auth/profile").then(
    ({ data }) => {
      dispatch(setAuth());
      const profile = {
        id: data.id,
        account_type: data.account_type,
        email: data.email,
        avatar: data.avatar,
        name: data.name,
        clinic: data.clinic,
        phone_number: data.phone_number,
        additional_phone_number: data.additional_phone_number,
        country: data.country,
        address: data.address,
        send_email_notifications: data.send_email_notifications,
        send_telegram_notifications: data.send_telegram_notifications,
      };
      dispatch(obtainProfileData(profile));
    },
    (error) => {
      dispatch(setAuth(true));
      dispatch(obtainProfileError(error));
    },
  );
};

// Update profile
export const updateProfile = ({
  name,
  clinic,
  phone_number,
  country,
  send_email_notifications,
  send_telegram_notifications,
}) => (dispatch) => {
  return API.put("auth/profile", {
    name,
    clinic,
    phone_number,
    country,
    send_email_notifications,
    send_telegram_notifications,
  }).then(
    (response) => {
      Logger.info(response);
      dispatch(obtainProfileData(response.data));
      dispatch(setCardAdd(true));
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainProfileError(error));
    },
  );
};

export const updateProfileOwner = ({ phone_number, additional_phone_number, address }) => (dispatch) => {
  return API.put("auth/profile", {
    phone_number,
    additional_phone_number,
    address,
  }).then(
    (response) => {
      Logger.info(response);
      dispatch(obtainProfileData(response.data));
      dispatch(setCardAdd(true));
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainProfileError(error));
    },
  );
};

export const switchTopBar = (theme) => (dispatch) => {
  return API.put("auth/profile", {
    theme,
  }).then(
    (response) => {
      Logger.info(response);
      dispatch(obtainProfileData(response.data));
    },
    (error) => {
      Logger.info(error);
    },
  );
};
