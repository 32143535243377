import {
  TOGGLE_APPOINTMENT_DIALOG,
  OBTAIN_APPOINTMENTS_DATA,
  OBTAIN_APPOINTMENTS_ERROR,
  OBTAIN_APPOINTMENTS_LOADING,
  OBTAIN_APPOINTMENTS_SEARCH_CANCEL_TOKEN,
} from "../actions";

const initialState = {
  appointmentsList: [],
  appointmentsListError: [],

  appointmentsListNextCursor: null,
  appointmentsListQuery: null,
  appointmentsListLoading: false,
  appointmentsListCancelToken: null,
  isAppointmentDialog: false,
  isCardIdAppointment: null,
  appointmentId: null,
};

export default function appointmentsReducer(state = initialState, action) {
  switch (action.type) {
    // TODO REFACTOR
    case TOGGLE_APPOINTMENT_DIALOG:
      return {
        ...state,
        isAppointmentDialog: action.payload.isShow,
        isCardIdAppointment: action.payload.cardId,
        isAppointmentId: action.payload.appointmentId,
      };

    case OBTAIN_APPOINTMENTS_DATA:
      return {
        ...state,
        appointmentsList: action.payload.data,
        appointmentsListError: [],
        appointmentsListNextCursor: action.payload.nextCursor,
        appointmentsListQuery: action.payload.query,
      };
    case OBTAIN_APPOINTMENTS_ERROR:
      return {
        ...state,
        appointmentsList: [],
        appointmentsListError: action.payload,
        appointmentsListNextCursor: null,
        appointmentsListQuery: null,
      };
    case OBTAIN_APPOINTMENTS_LOADING:
      return {
        ...state,
        appointmentsListLoading: action.payload,
      };
    case OBTAIN_APPOINTMENTS_SEARCH_CANCEL_TOKEN:
      return { ...state, appointmentsListCancelToken: action.payload };
    default:
      return state;
  }
}
